.about-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
  color: #333;
}

.about-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #222;
  border-bottom: 2px solid #ddd;
  display: inline-block;
  padding-bottom: 10px;
}

.product-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap; /* 화면 크기에 따라 제품 정렬 */
  margin-top: 20px;
}

.product {
  max-width: 500px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 350px; /* 고정된 너비 */
  height: 380px; /* 고정된 높이 */
  object-fit: cover; /* 이미지 비율을 유지하며 자름 */
  border-radius: 10px;
  margin-bottom: 15px;
}

.product h3 {
  font-size: 24px;
  color: #007bff;
  margin-bottom: 10px;
}

.product p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.about-description {
  margin-top: 40px;
  font-size: 18px;
  color: #666;
  line-height: 1.8;
}
