.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../assets/background.jpg') no-repeat center center/cover;
  position: relative; /* 글자와 배경을 겹치기 위해 */
  color: #fff;
  text-align: center;
  overflow: hidden; /* 필요시 잘림 방지 */
}

.main-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경 어둡게 처리 */
  z-index: 0; /* 글자보다 뒤로 보내기 */
}

.main-section h1 {
  font-size: 60px; /* 더 커진 글씨 */
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase; /* 모든 글자를 대문자로 */
  letter-spacing: 2px; /* 글자 간격 추가 */
  color: #ffcc00; /* 강조된 색상 */
  z-index: 1; /* 배경보다 위에 표시 */
}

.main-section p {
  font-size: 28px; /* 텍스트 크기 키움 */
  color: #fff; /* 글씨 흰색 */
  font-weight: 400;
  font-style: italic; /* 이탤릭 스타일 추가 */
  z-index: 1; /* 배경보다 위에 표시 */
  margin-top: 10px;
}

.image-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 30px;
  z-index: 1; /* 이미지도 배경 위에 표시 */
}

.image-container img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.image-container img:hover {
  transform: scale(1.7);
}
