.App {
  text-align: center;
  padding-top: 50px;
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #61dafb;
  text-decoration: none;
  font-size: 18px;
}

section {
  margin: 40px;
}

h2 {
  color: #282c34;
}

p {
  font-size: 16px;
  color: #555;
}
