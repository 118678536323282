.header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* 다른 요소들 위에 표시되도록 함 */
  }
  
  .logo {
    padding-left: 30px;
    font-size: 34px;
    font-family :initial;
    font-weight:bolder;
  }
  
  .logo a {
    text-decoration: none;
    color: #333;
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav ul li {
    margin-left: 20px;
    padding-right: 50px;
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 23px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  
  .nav ul li a:hover {
    color: #007bff;
  }
  