.contact-section {
  padding: 60px 20px;
  background-color: #f9f9f9; /* 부드러운 하얀색 배경 */
  border-radius: 40px; /* 둥근 모서리 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자 효과 */
  width: 100%; /* 화면 끝까지 확장 */
  max-width: 1780px; /* 중앙 콘텐츠 너비 제한 */
  margin: 40px auto; /* 수평 중앙 정렬 */
  text-align: left; /* 텍스트 왼쪽 정렬 */
  color: #333; /* 기본 검정 텍스트 */
  position: relative; /* 배경 효과를 위한 상대 위치 */
}

.contact-section h2 {
  font-size: 36px;
  color: #222;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd; /* 밑줄로 구분 */
  padding-bottom: 10px;
}

.contact-section h3 {
  font-size: 28px;
  color: #007bff;
  margin-bottom: 10px;
}

.contact-section p {
  font-size: 18px;
  line-height: 1.8; /* 가독성을 위한 줄 간격 */
  color: #555;
  margin-bottom: 10px;
}

.contact-section p:last-child {
  margin-bottom: 0; /* 마지막 줄 간격 제거 */
}

.contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05); /* 약간의 어두운 오버레이 */
  z-index: -1; /* 배경 뒤로 */
  border-radius: 40px; /* 둥근 배경 */
}

@media (max-width: 768px) {
  .contact-section {
    padding: 40px 15px;
  }

  .contact-section h2 {
    font-size: 28px;
  }

  .contact-section h3 {
    font-size: 24px;
  }

  .contact-section p {
    font-size: 16px;
  }
}
